import { CloseButtonIcon } from 'src/components/Icons/CloseButtonIcon'
import './modal.scss'

interface ModalProps {
  title: string
  textContent: string
  buttonTitle: string
  buttonAction: () => void
  htmlTextBottom: string
  setCpfModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export function Modal(props: ModalProps) {
  const { setCpfModalOpen } = props

  return (
    <div className="modal-update__content">
      <div className="modal__content--overlay" />
      <div className="wrapper">
        <button className="closeButton" onClick={() => setCpfModalOpen(false)}>
          <CloseButtonIcon />
        </button>
        <h2 className="title">{props.title}</h2>
        <p className="textContent">{props.textContent}</p>
        <button className="button" onClick={() => props.buttonAction()}>
          {props.buttonTitle}
        </button>
        <p
          className="htmlTextBottom"
          dangerouslySetInnerHTML={{ __html: props.htmlTextBottom }}
        />
      </div>
    </div>
  )
}
